import {
  addMinutes,
  addSeconds,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  format,
  parseISO,
  parseJSON,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from 'date-fns';

export const danishFormat = 'dd-MM-yyyy';
export const danishFormatWithTime = 'dd-MM-yyyy';
export const danishFormatRegex = /\d{2}-\d{2}-\d{4}/;

export enum dayInWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAYS = 6,
  SUNDAY = 0,
}

// tslint:disable-next-line: typedef
export const SetHours = (dato: Date | string, Hour: string): Date => {
  let resultdate: Date = dato instanceof Date ? dato : parseJSON(dato);
  const s: string[] = Hour.split(':');
  if (s.length > 0) {
    resultdate = setHours(resultdate, parseInt(s[0], 0));
    if (s.length > 1) {
      resultdate = setMinutes(resultdate, parseInt(s[1], 0));
      if (s.length > 2) {
        resultdate = setSeconds(resultdate, parseInt(s[2], 0));
      } else {
        resultdate = setSeconds(resultdate, 0);
      }
    } else {
      resultdate = setMinutes(resultdate, 0);
      resultdate = setSeconds(resultdate, 0);
    }
  } else {
    resultdate = setHours(resultdate, 0);
    resultdate = setMinutes(resultdate, 0);
    resultdate = setSeconds(resultdate, 0);
  }
  return resultdate;
};

export const dateFromApi = (date: Date | string): Date => {
  if (!date) {
    // what to do when passed an undefined date
    throw new Error(
      'Passed value was undefined when it should have been either a date object or a string.',
    );
  }

  if (date instanceof Date) {
    return date;
  }
  return parseISO(date);
};

export const convertDateToUtc = (date: Date): Date => {
  const offset: number = 0 - date.getTimezoneOffset();
  return addMinutes(date, offset);
};

export const objectToDate = (obj: string | Date): Date => {
  if (typeof obj === 'string') {
    return parseISO(obj);
  } else {
    return obj;
  }
};
export const convertDateOnlyToUtc = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const convertDateTimeToUtc = (date: Date) => {
  return format(date, "yyyy-MM-dd' 'HH:mm");
};

export const timeFormatToMinutets = (minutes: string): number => {
  const timeRegex = /(0\d|1\d|2[0-3]|\d):([0-5]\d)/;
  if (minutes.length == 1) {
    minutes = '0' + minutes;
  }
  minutes = minutes.replace(':', '') + '0000';
  minutes = minutes.slice(0, 2) + ':' + minutes.slice(2, 2);
  const res = minutes.match(timeRegex);
  if (res) {
    return +res[2] + +res[1] * 60;
  } else {
    return 0;
  }
};
export const dateToMinuts = (date: Date): number => {
  const startday = startOfDay(date);
  return differenceInMinutes(date, startday);
};

export const minutesToTimeFormat = (minutes: number): string => {
  if (minutes === 0 || minutes === 1440) {
    return '00:00';
  }
  const helperDate: Date = addSeconds(new Date(0), minutes);
  return format(helperDate, 'mm:ss');
};

export const timeToMinutes = (time: string, toTime = false): number => {
  if (toTime && time === '00:00') {
    return 1440; // 24:00
  }
  const hours: number = parseInt(time.split(':')[0]);
  const minutes: number = parseInt(time.split(':')[1]);
  return hours * 60 + minutes;
};

export const getDateOfISOWeek = (w: number, y: number): Date => {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  return ISOweekStart;
};

/**
 * Iperiode functions
 */
export interface IPeriode {
  fra: Date;
  tilOgMed: Date;
  Objects?: any[];
}

export const newTimeperiod = (): IPeriode => {
  return {
    fra: new Date(),
    tilOgMed: new Date(),
  };
};

export const newSearchSpace = (period1: IPeriode, period2: IPeriode): IPeriode => {
  const maxSearch = endOfDay(getMaxTo(period1, period2));
  const lowSearch = startOfDay(getLowestFrom(period1, period2));

  return {
    fra: lowSearch,
    tilOgMed: maxSearch,
  };
};

export const areTimeperiodsOverlapping = (period1: IPeriode, period2: IPeriode) => {
  return period1.fra < period2.tilOgMed && period2.fra < period1.tilOgMed;
};

export const getMaxTo = (period1: IPeriode, period2: IPeriode): Date => {
  return period1.tilOgMed > period2.tilOgMed ? period1.tilOgMed : period2.tilOgMed;
};

export const getLowestFrom = (period1: IPeriode, period2: IPeriode): Date => {
  return period1.fra < period2.fra ? period1.fra : period2.fra;
};

export const isCompletelyWrapped = (bigPeriod: IPeriode, smallPeriod: IPeriode): boolean => {
  return smallPeriod.fra >= bigPeriod.fra && smallPeriod.tilOgMed <= bigPeriod.tilOgMed;
};

export const overlapDay = (period: IPeriode, date: Date): boolean => {
  return period.fra <= date && period.tilOgMed >= date;
};

export const getDatesBetween = (period: IPeriode): Date[] => {
  return eachDayOfInterval({
    start: period.fra,
    end: period.tilOgMed,
  });
};
