export enum eBrandColor {
  xxdark = '#00404E',
  xdark = '#00586C',
  dark = '#006A83',
  standard = '#007E94',
  light = '#48ABBE',
  xlight = '#91D1DF',
  xxlight = '#D9F1F6',
}

export enum eGreyColor {
  black = '#000000',
  xxdark = '#262626',
  xdark = '#484848',
  dark = '#808080',
  standard = '#A1A1A1',
  light = '#E5E5E5',
  xlight = '#F0F0F0',
  xxlight = '#F8F8F8',
  white = '#FFFFFF',
}

export enum eDangerColor {
  xxdark = '#B53329',
  xdark = '#CF3B2F',
  dark = '#E84234',
  standard = '#FF493A',
  light = '#FF8178',
  xlight = '#FFB8B3',
  xxlight = '#FFE3E1',
}

export enum eAlertColor {
  xxdark = '#B38800',
  xdark = '#C49B00',
  dark = '#E1B800',
  standard = '#FFD600',
  light = '#FFE77B',
  xlight = '#FFEFA7',
  xxlight = '#FFFAD9',
}

export enum eSuccessColor {
  xxdark = '#357F52',
  xdark = '#42A268',
  dark = '#4FBF7C',
  standard = '#57D48A',
  light = '#8FE3B1',
  xlight = '#b1edc8',
  xxlight = '#e6faec',
}

export enum eMermaidColor {
  xxdark = '#128072',
  xdark = '#19A895',
  dark = '#1FBFAA',
  standard = '#26D5BE',
  light = '#64E1D0',
  xlight = '#A1EDE3',
  xxlight = '#DFF9F5',
}

export enum eAquaColor {
  xxdark = '#2c7Da1',
  xdark = '#39A1CE',
  dark = '#3FB5E7',
  standard = '#46C9FF',
  light = '#82D9FF',
  xlight = '#B8E9FF',
  xxlight = '#DBF4FF',
}

export enum eSquidColor {
  xxdark = '#4E48B5',
  xdark = '#5a52CF',
  dark = '#655Ce9',
  standard = '#7066FF',
  light = '#8D8DFF',
  xlight = '#C6C3FF',
  xxlight = '#E3E1FF',
}

export enum eAnemoneColor {
  xxdark = '#B52952',
  xdark = '#CF2F5C',
  dark = '#E83568',
  standard = '#FF3B74',
  light = '#FF709A',
  xlight = '#FFA6BF',
  xxlight = '#FFD9E4',
}

export enum eStarfishColor {
  xxdark = '#A65200',
  xdark = '#C46100',
  dark = '#E16F00',
  standard = '#FF7E00',
  light = '#FFA556',
  xlight = '#FFBF87',
  xxlight = '#FFE6D0',
}

// Turn enum into array
function ToArray(enumme) {
  return Object.keys(enumme).map((key) => enumme[key]);
}

const ColorMixin = {
  methods: {
    brandcolors(): string[] {
      return ToArray(eBrandColor);
    },

    greycolors(): string[] {
      return ToArray(eGreyColor);
    },

    dangercolors(): string[] {
      return ToArray(eDangerColor);
    },

    alertcolors(): string[] {
      return ToArray(eAlertColor);
    },

    successcolors(): string[] {
      return ToArray(eSuccessColor);
    },

    mermaidColors(): string[] {
      return ToArray(eMermaidColor);
    },

    aquaColors(): string[] {
      return ToArray(eAquaColor);
    },

    squidColors(): string[] {
      return ToArray(eSquidColor);
    },

    anemoneColors(): string[] {
      return ToArray(eAnemoneColor);
    },
    starfishColors(): string[] {
      return ToArray(eStarfishColor);
    },

    getAllISTcolors() {
      return [
        ...this.brandcolors(),
        ...this.dangercolors(),
        ...this.alertcolors(),
        ...this.successcolors(),
        ...this.mermaidColors(),
        ...this.aquaColors(),
        ...this.squidColors(),
        ...this.anemoneColors(),
        ...this.starfishColors(),
        ...this.greycolors(),
      ];
    },

    pickTextColorBasedOnBgColorAdvanced(
      bgColor: string,
      lightColor: string,
      darkColor: string,
    ): string {
      const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
      const r = parseInt(color.substring(0, 2), 16); // hexToR
      const g = parseInt(color.substring(2, 4), 16); // hexToG
      const b = parseInt(color.substring(4, 6), 16); // hexToB
      const uicolors = [r / 255, g / 255, b / 255];
      const c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
      return L > 0.279 ? darkColor : lightColor;
    },
    pickTextColorBasedOnBgColor(bgColor: string) {
      return this.pickTextColorBasedOnBgColorAdvanced(bgColor, eGreyColor.white, eGreyColor.black);
    },
  },
};
export default ColorMixin;
