<template>
  <label
    :data-test-id="dataTest"
    class="checkbox-container"
    :class="{ disabled: disable }">
    <input
      data-test-id="checkbox"
      :disabled="disable"
      type="checkbox"
      :value="value"
      v-model="selected" />
    <span class="checkmark" /> </label
  ><slot />
</template>

<script setup lang="ts">
  interface Props {
    modelValue: boolean | string[];
    value?: string;
    disable?: boolean;
    readonly?: boolean;
    dataTest?: string;
  }

  const props = defineProps<Props>();

  const selected = computed({
    get() {
      return props.modelValue;
    },
    set(event: boolean | string[]) {
      emit('update:modelValue', event);
    },
  });

  const emit = defineEmits<(e: 'update:modelValue', value: boolean | string[]) => void>();
</script>

<style scoped lang="scss">
  .checkbox-container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    height: 24px;
    width: 24px;
    align-self: center;
  }
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .disabled {
    cursor: not-allowed;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 4px;
    background-color: $ist-color-white;
    border: 1px solid $ist-color-grey;
  }
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $ist-color-grey-dark;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 8px;
    top: 1px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
</style>
