<template>
  <div
    data-test-id="ist-chip"
    class="ist-chip-container"
    :class="[chipClass, chipType, { elevated: elevated }]">
    <div class="ist-chip-label">
      <slot>
        <div
          data-test-id="ist-chip-label"
          class="ist-chip-label">
          {{ label }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { BUTTON_SIZES } from '@/assets/template';

  export type ChipType = 'regular' | 'round';
  export type ChipClass = 'default' | 'danger' | 'warning' | 'info' | 'normal';
  export type ChipSize = 'sm' | 'md' | 'lg' | 'xl';

  interface ChipProps {
    label?: string;
    chipClass?: ChipClass;
    chipType?: ChipType;
    size?: ChipSize;
    elevated?: boolean;
  }

  const {
    label,
    chipClass = 'default',
    chipType = 'regular',
    size,
    elevated = false,
  } = defineProps<ChipProps>();

  const defaultSize = computed<ChipSize>(() => size ?? 'md');

  const chipSize = computed((): string => {
    const minWidht = chipType === 'round' ? 1 : 3;
    return BUTTON_SIZES[defaultSize.value] * minWidht + 'px';
  });
</script>

<style scoped lang="scss">
  .ist-chip-container {
    border: none;
    display: inline-flex;
  }
  .ist-chip-label {
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .regular {
    border-radius: 25px;
    min-width: 130px;
    height: 30px;
    padding: 0 16px 0 16px;
  }
  .round {
    border-radius: 100%;
    width: v-bind(chipSize);
    height: v-bind(chipSize);
  }

  .default {
    background-color: $ist-color-grey;
    min-width: v-bind(chipSize);
  }
  .info {
    background-color: $ist-color-blue;
    color: $ist-color-white;
  }
  .danger {
    background-color: $ist-color-red;
    color: $ist-color-white;
  }

  .warning {
    background-color: $ist-color-yellow;
  }

  .elevated {
    box-shadow:
      0 1px 5px #0003,
      0 2px 2px #00000024,
      0 3px 1px -2px #0000001f;
  }
  .normal {
    background-color: $ist-color-green;
  }
</style>
