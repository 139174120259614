<template>
  <div class="person-item-container">
    <ISTCheckBox
      :value="id"
      v-model="selected"
      v-if="personSelect && selectLeft" />
    <ISTAvatar
      v-if="avatar"
      class="list-avatar"
      :full-name="fullName" />
    <router-link
      class="person-item-link"
      v-if="linkto"
      :to="linkto">
      {{ fullName }}
    </router-link>
    <div
      v-else
      class="person-item-name">
      {{ fullName }}
    </div>
    <div class="item-slot">
      <slot />
    </div>

    <ISTButton
      rounded
      size="sm"
      @click="handleActionBtnClick"
      v-if="actionBtnLabel">
      {{ actionBtnLabel }}
    </ISTButton>
    <ISTCheckBox
      :value="id"
      v-model="selected"
      v-if="personSelect && !selectLeft" />
    <div
      v-if="moreMenuItems"
      class="list-item-icon-container">
      <div>
        <ISTButton
          icon-name="r_more_vert"
          button-style="showmore" />
        <q-menu v-model="showMenu">
          <q-list style="min-width: 100px">
            <q-item
              v-for="menu in moreMenuItems"
              :key="menu.action"
              @click="handleMenuAction(menu.action)"
              clickable
              v-close-popup>
              <q-item-section>{{ menu.title }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';

  const showMenu = ref<boolean>(false);

  interface MoreMenuItem {
    title: string;
    action: string;
  }

  interface Props {
    id: string;
    fornavn: string;
    efternavn: string;
    moreMenuItems?: MoreMenuItem[];
    actionBtnLabel?: string;
    personSelect?: boolean;
    linkto?: string;
    selectLeft?: boolean;
    avatar?: boolean;
    modelValue?: string[];
  }

  const {
    id,
    fornavn,
    efternavn,
    moreMenuItems,
    actionBtnLabel,
    personSelect,
    linkto,
    selectLeft,
    avatar,
    modelValue = [],
  } = defineProps<Props>();
  const emit = defineEmits<{
    (e: 'menu-action', payload: { action: string; id: string }): void;
    (e: 'action-btn-click', id: string): void;
    (e: 'update:modelValue', value: string[]): void;
  }>();

  const selected = computed({
    get() {
      return modelValue;
    },
    set(event: string[]) {
      emit('update:modelValue', event);
    },
  });

  const fullName = computed(() => {
    return `${fornavn} ${efternavn} `;
  });

  const handleMenuAction = (action: string) => {
    emit('menu-action', { action, id: id });
  };

  const handleActionBtnClick = () => {
    emit('action-btn-click', id);
  };
</script>

<style scoped lang="scss">
  .person-item-container {
    min-height: 30px;
    min-width: 600px;
    transition: background-color 0.3s ease;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 12px;
  }
  .person-item-name {
    display: flex;
    min-width: 300px;
    margin: auto 0;
    flex-direction: row;
    gap: 12px;
  }

  .person-item-link {
    text-decoration: none;
  }

  .item-slot {
    width: 30px;
  }
</style>
