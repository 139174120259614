import { UserType } from '@/generated/graphqlUser';
import i18n from '@/i18n';
import { IModule } from '../security/models/module-model';

export default <IModule>{
  routes: [
    {
      path: '/main',
      name: 'main',
      meta: { requiresAuth: true, title: i18n.global.t('menu.Frontpage') },
      component: () => import(/* webpackChunkName: "main" */ './dashboard/MainDashboard.vue'),
    },
  ],
  menu:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [
      {
        title: i18n.global.t('menu.Frontpage'),
        entryPointUrl: '/main',
        iconName: 'mdi-view-quilt',
        name: 'main',
        priority: 1,
        hideFrom: UserType.TilbudsmedarbejderAdmin,
        userRightNames: ['menupunktforside'],
      },
    ],
};
