import { createApp } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import { Dialog, Notify, Quasar, QuasarPluginOptions } from 'quasar';
import iconSetround from 'quasar/icon-set/material-icons-round';
import langDa from 'quasar/lang/da';
import I18n from './i18n';
import './quasar';
import { IstComponents } from '@ist/brdk_uigruppe';
import Axios from 'axios';
import vfmPlugin from 'vue-final-modal';
import App from './App.vue';
import ISTcomponentsIndex from './components/ISTcomponentsIndex';
import { apolloClient } from './graphql/apolloClient';
import { useSecurityStore } from './modules/security/store-index';
import router from './navigation/router';
import { useNavigationStore } from './store';
import './assets/animate.css';
import 'quasar/src/css/index.sass';
import './assets/quasar.scss';
import './assets/ist-style.min.scss';
import './assets/css/output.css';
import VueMatomo from 'vue-matomo';
import { initializeKeycloak } from './auth/authentification';
import { useMainStore } from './store/store';

const quasarConfig: Partial<QuasarPluginOptions> = {
  lang: langDa,
  config: {
    // @ts-ignore
    brand: {
      primary: '#007e94',
      secondary: '#ff7a00',
      accent: '#00586c',
      positive: '#57d48a',
      negative: '#ff493a',
      info: '#91d1df',
      warning: '#ffd600',
      danger: '#ff493a',
    },
  },
  plugins: { Notify, Dialog },
  iconSet: iconSetround,
};

const app = createApp(App)
  .use(createPinia())
  .use(I18n)
  .use(ISTcomponentsIndex)
  .use(Quasar, quasarConfig);

const mainStore = useMainStore();
app.config.performance = true;

mainStore.fetchEnvironment().then(async () => {
  await initializeKeycloak(mainStore.mainConfig);
  app.use(router);

  app.use(vfmPlugin());
  app.use(IstComponents as any);
  app.config.globalProperties.$http = Axios;
  app.config.globalProperties.$router = router;
  app.provide(DefaultApolloClient, apolloClient);
  app.use(VueMatomo, {
    host: 'https://matomo.ist.com',
    siteId: 27,
    router: router,
    enableLinkTracking: true,
    trackPageView: true,
  });
  app.mount('#app');
  useNavigationStore().loadMenu();

  const props = router.resolve({
    name: '',
  });

  useSecurityStore().setLogoutUrl(new URL(props.href, window.location.href || '').href);
});
