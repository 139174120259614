import { InMemoryCache } from '@apollo/client/core';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        boern: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Kommune: {
      fields: {
        skabeloner: {
          read(skabeloner = []) {
            return skabeloner.map((skabelon) => ({
              ...skabelon,
              created: new Date(skabelon.created),
              lastUpdated: new Date(skabelon.lastUpdated),
            }));
          },
        },
      },
    },
    Kontrol: {
      fields: {
        deadline: {
          read(deadline) {
            return new Date(deadline);
          },
        },
        created: {
          read(created) {
            return new Date(created);
          },
        },
      },
    },
  },
});
