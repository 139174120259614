import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import { apolloClient } from '@/graphql/apolloClient';
import { MainConfig } from '@/store/types';

const uriHelper = (path: string) => {
  const portStub = window.location.port ? ':' + window.location.port : '';
  const redirect = window.location.protocol + '//' + window.location.hostname + portStub + path;
  return redirect;
};

let keycloakAuth: Keycloak;

export const initializeKeycloak = async (mainConfig: MainConfig) => {
  if (window.sessionStorage['useSupportToolTimestamp']) {
    // if timestamp is set, we need to check if it is older than 5 minutes
    const timestamp = parseInt(window.sessionStorage['useSupportToolTimestamp']);
    const now = new Date().getTime();
    if (now - timestamp > 300000) {
      // if it is older than 5 minutes, we need to remove the timestamp
      window.sessionStorage.removeItem('useSupportToolTimestamp');
    } else {
      // if it is not older than 5 minutes, we need to set the realm and client id to the support tool
      mainConfig.KEYCLOAK_REALM = mainConfig.KEYCLOAK_SUPPORTTOOL_REALM;
      mainConfig.KEYCLOAK_CLIENTID = mainConfig.KEYCLOAK_SUPPORTTOOL_CLIENTID;
    }
  }
  keycloakAuth = new Keycloak({
    url: mainConfig.KEYCLOAK_URL,
    realm: mainConfig.KEYCLOAK_REALM,
    clientId: mainConfig.KEYCLOAK_CLIENTID,
  });

  // Check local storage - e2e tests put login details here
  if (window.localStorage['e2eToken']) {
    // since we are inside a test, we need to copy the token to session storage
    window.sessionStorage['token'] = window.localStorage['e2eToken'];
    window.sessionStorage['refreshToken'] = window.localStorage['e2eRefreshToken'];
    // and we should be able to return here without initializing keycloak
    return;
  }

  const token = sessionStorage.getItem('token') ?? '';
  const refreshToken = sessionStorage.getItem('refreshToken') ?? '';
  const idToken = sessionStorage.getItem('idToken') ?? '';

  try {
    await keycloakAuth.init({
      checkLoginIframe: false,
      token: token,
      refreshToken,
      idToken,
    });
  } catch (e) {
    console.error('Keycloak init failed', e);
  }

  if (keycloakAuth.authenticated) {
    sessionStorage.setItem('token', keycloakAuth.token ?? '');
    sessionStorage.setItem('refreshToken', keycloakAuth.refreshToken ?? '');
    sessionStorage.setItem('idToken', keycloakAuth.idToken ?? '');
  }
};

export const getToken = (): string | undefined => {
  if (window.localStorage['e2eToken']) {
    return window.localStorage['e2eToken'];
  }
  if (!keycloakAuth) {
    return;
  }
  if (keycloakAuth.token) {
    return keycloakAuth.token;
  }
};

export const isAuthenticated = () => {
  if (window.localStorage['e2eToken']) {
    return true;
  }

  return keycloakAuth.authenticated;
};

export const login = (mainConfig: MainConfig, path?: string, option?: string) => {
  const stub = !path || path.length < 2 ? '/main' : path;

  if (!keycloakAuth) {
    return;
  }
  // In case it has been override by the support tool, we reset it to the main realm and client id
  keycloakAuth.realm = mainConfig.KEYCLOAK_REALM;
  keycloakAuth.clientId = mainConfig.KEYCLOAK_CLIENTID;

  const kcLogin = keycloakAuth.login;
  if (option && option !== '') {
    keycloakAuth.login = (options: KeycloakLoginOptions): Promise<void> => {
      options.idpHint = option;
      return kcLogin(options);
    };
  }
  kcLogin({ idpHint: option, redirectUri: uriHelper(stub ?? '/main') });
};

export const logout = (): void => {
  if (!keycloakAuth) {
    return;
  }

  apolloClient.clearStore();
  window.sessionStorage['token'] = '';
  window.sessionStorage['refreshToken'] = '';
  window.sessionStorage['idToken'] = '';

  keycloakAuth.logout({
    redirectUri: uriHelper('/login'),
  });
};

export const loginSupport = (mainConfig: MainConfig): void => {
  if (!keycloakAuth) {
    return;
  }
  keycloakAuth.realm = mainConfig.KEYCLOAK_SUPPORTTOOL_REALM;
  keycloakAuth.clientId = mainConfig.KEYCLOAK_SUPPORTTOOL_CLIENTID;
  window.sessionStorage['useSupportToolTimestamp'] = new Date().getTime().toString();
  keycloakAuth.login({ redirectUri: uriHelper('/') });
};

window.setInterval(async () => {
  if (!keycloakAuth) {
    return;
  }

  const token = getToken();
  if (!token) {
    return;
  }

  try {
    const refreshed = await keycloakAuth.updateToken(70);
    if (refreshed) {
      window.sessionStorage['token'] = keycloakAuth.token;
      window.sessionStorage['refreshToken'] = keycloakAuth.refreshToken;
      window.sessionStorage['idToken'] = keycloakAuth.idToken;
    }
  } catch (e) {
    logout();
  }
}, 60000);
