import Router, { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { isAuthenticated } from '@/auth/authentification';
import { IModule } from '@/modules/security/models/module-model';
import { useSecurityStore } from '@/modules/security/store-index';

interface BreadCrumb {
  text: string;
  to?: {
    name: string;
    params?: {
      param: string;
    };
  };
}
declare module 'vue-router' {
  interface RouteMeta {
    // is optional
    title?: string;
    // must be declared by every route
    requiresAuth: boolean;
    breadCrumb?: (route?: RouteMeta) => BreadCrumb[];
  }
}

function loadExtraRoutes() {
  if (import.meta.env.ENV_NODE !== 'test') {
    const modules = import.meta.glob('../modules/**/settings.ts');
    const values = Object.values(modules);
    const modulesMapped = values.map(async (module) => {
      const moduleLocal: any = await module();
      return moduleLocal.default;
    });
    return modulesMapped;
  } else {
    return [];
  }
}

export async function addRoutes() {
  const extraRoutes = loadExtraRoutes();
  extraRoutes.forEach(async (settings: Promise<IModule>) => {
    const localSettings = await settings;
    localSettings.routes.forEach((route: RouteRecordRaw) => {
      router.addRoute('mainPage', route);
    });
  });
}

const router: Router.Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login/:routeStub?',
      name: 'login',
      props: true,
      component: () => import('../views/StartPage.vue'),
      meta: { requiresAuth: false, title: 'Startside' },
    },
    {
      path: '/supporttool',
      name: 'supporttool',
      props: true,
      component: () => import('../views/LoginSupport.vue'),
      meta: { requiresAuth: false, title: 'Supportool' },
    },
    {
      path: '/',
      name: 'mainPage',
      component: () => import('../views/_Layout.vue'),
      meta: { requiresAuth: true, title: 'Startside' },
    },

    {
      path: '/tilbudsadmin/:tilbud_code',
      name: 'tilbudsadminKode',
      meta: { requiresAuth: true, title: 'Tilmeld tilbud' },
      component: () => import('../modules/main/indtastkode/IndtastKodeView.vue'),
    },
    {
      path: '/firstlogin/',
      name: 'firstlogin',
      meta: { requiresAuth: true, title: 'Velkommen til Personkreds' },
      component: () => import('../modules/main/firstLogin/firstLoginForvalter.vue'),
    },
    {
      path: '/error',
      name: 'error',
      meta: { requiresAuth: false, title: 'Der skete en fejl' },
      component: () => import('../views/ShowServerMessage.vue'),
    },
    {
      path: '/invalid',
      name: 'invalid',
      component: () => import('../modules/main/ukendt/ErrorView.vue'),
      meta: { requiresAuth: false, title: '404' },
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('../modules/main/ukendt/500Error.vue'),
      meta: { requiresAuth: false, title: '500' },
    },
  ],
});

// check if a
router.beforeEach((to) => {
  // const authorize: string[] = to.meta.authorize as string[];

  const { hasCustomUserErrors } = useSecurityStore();

  if (hasCustomUserErrors && to.name !== 'tilbudsadminKode' && to.name !== 'error') {
    return { name: 'error' };
  }

  if (to.path !== '/login' && to.meta && to.meta.requiresAuth === true && !isAuthenticated()) {
    return { name: 'login', params: { routeStub: to.path } };
  }

  // This abomination is due to us wanting to have different landing pages for different roles.
  if (to.path === '/') {
    return { name: 'main' };
  }
});

// Use after each hook to scrub state path from url, using vue routers history manipulation
router.beforeResolve((route) => {
  const { fullPath } = route;
  const hasStateStub = fullPath.includes('#state=');
  if (hasStateStub) {
    const firstIndexOfHashtag = fullPath.indexOf('#');
    const newPath = fullPath.substring(0, firstIndexOfHashtag);
    router.replace(newPath);
  }
});

addRoutes();

export default router;
