<template>
  <ul>
    <li
      v-for="item in itemList"
      :key="item.text">
      <router-link
        :to="linkTo"
        v-if="linkTo">
        <div
          v-ripple
          :class="[ValidationIssueListClass, getItemClass(item.validationIssue), itemClass]">
          {{ item.text }}
        </div>
      </router-link>
      <div
        @click="handleClick(item.validationIssue)"
        v-else
        :class="[ValidationIssueListClass, getItemClass(item.validationIssue), itemClass]">
        {{ item.text }}
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
  import { ValidationIssue, ValidationIssueItem } from './types';

  interface Props {
    itemList: ValidationIssueItem[];
    linkTo?: string;
    size?: 'sm' | 'md' | 'lg';
  }

  const props = defineProps<Props>();
  const emit = defineEmits<{
    (e: 'select-issue', value: ValidationIssue);
  }>();

  const itemClass = computed((): string => {
    switch (props.size) {
      case 'sm': {
        return 'small';
      }
      case 'md': {
        return 'medium';
      }
      case 'lg':
        return 'large';
      default:
        return 'medium';
    }
  });

  const ValidationIssueListClass = 'validation-issue-list-item';

  const getItemClass = (validationIssue: ValidationIssue): string => {
    switch (validationIssue) {
      case ValidationIssue.Medarbejder:
        return 'danger';
      case ValidationIssue.Validering:
        return 'danger';
      default:
        return 'attention';
    }
  };

  const handleClick = (issue: ValidationIssue) => {
    emit('select-issue', issue);
  };
</script>

<style lang="scss" scoped>
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style-type: none;
  }
  a {
    text-decoration: none;
  }

  .validation-issue-list-item {
    background-color: $ist-color-red-dark;
    color: $ist-color-white;
    list-style-type: none;
    cursor: pointer;
  }

  .small {
    padding: 4px 12px 4px;
    margin-bottom: 12px;
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 0 12px 0 0;
  }
  .medium {
    padding: 8px 18px 8px;
    margin-bottom: 12px;
    width: 220px;
  }
  .large {
    padding: 8px 18px 8px;
    margin-bottom: 12px;
    max-width: 280px;
  }

  .attention {
    background-color: $ist-color-orange;
  }

  .danger {
    background-color: $ist-color-red;
  }
</style>
