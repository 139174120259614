// <reference path="../typings.d.ts" />
import { Component } from 'vue';
import { v4 } from 'uuid';
import { $vfm } from 'vue-final-modal';

export abstract class modalDialog {
  public static myProp = 'Hello';

  public static open(dialogComponent: Component, params = {}, dialogSettings = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const name = v4();
        params = Object.assign(params, {
          name: params['name'] || name,
          onClose: resolve,
          focusRetain: false,
          focusTrap: false,
        });
        $vfm.show(
          {
            component: dialogComponent,
            bind: params,
            on: {
              // event by custom-modal
              confirm(close) {
                close();
              },
              cancel(close) {
                close();
              },
              close() {
                $vfm.hide(name);
              },
            },
          },
          dialogSettings,
        );
      } catch (error) {
        reject(error);
      }
    });
  }
}
