import { IModule } from '../security/models/module-model';

export default <IModule>{
  routes: [
    {
      path: '/support',
      name: 'support',
      meta: { requiresAuth: true, title: 'Support tool' },
      component: () => import('./SupportTool.vue'),
    },
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  menu: [
    {
      title: 'Supporttool',
      iconName: 'mdi-lifebuoy',
      entryPointUrl: '/support',
      priority: 500,
      name: 'support',
      userRightNames: ['SupportToolKundeSe'],
    },
  ],
};
