import { ViewAccessRight } from '@/generated/graphqlUser';
import i18n from '@/i18n';
import { IModule } from '../security/models/module-model';
import { useSecurityStore } from '../security/store-index';

export default <IModule>{
  routes: [
    {
      path: '/settings',
      name: 'settings',
      meta: {
        requiresAuth: true,
        title: i18n.global.t('menu.Settings'),
        authorize: [],
      },
      component: () => import(/* webpackChunkName: "settings" */ './main.vue'),
    },
    {
      path: '/settings/datakontrol',
      name: 'indstillinger-datakontrol',
      meta: { requiresAuth: true, title: 'Indstillinger Datakontrol' },

      beforeEnter: async () => {
        const store = useSecurityStore();
        await store.fetchUser();

        if (!store.can(ViewAccessRight.Read, 'MenupunktDatakontrolIndstillinger')) {
          return { name: 'main' };
        }
      },
      component: () => import('./forvalter/views/datakontrol/KontrolSettingsCard.vue'),
      children: [
        {
          path: 'skabelon',
          name: 'kontrolskabeloner',

          component: () => import('./forvalter/views/datakontrol/KontrolSkabelonerTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'institutioner',
          name: 'institutioner',

          component: () => import('./forvalter/views/datakontrol/InstitutionerTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'valideringsregler',
          name: 'valideringsregler',

          component: () =>
            import('./forvalter/views/datakontrol/ValideringsreglerTab/ValideringsReglerTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'grupper',
          name: 'grupper',

          component: () => import('./forvalter/views/datakontrol/KontrolGrupperTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'kontrolloerer',
          name: 'kontrolloerer',

          component: () => import('./forvalter/views/datakontrol/KontrolloererTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'roller',
          name: 'roller',

          component: () => import('./forvalter/views/datakontrol/RolleMappingTab.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'skabelon/:id',
          name: 'kontrolskabelon',

          component: () => import('./forvalter/views/datakontrol/Skabelon/KontrolSkabelon.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
          },
        },
        {
          path: 'grupper/:id',
          name: 'kontrolgruppe',

          component: () => import('./forvalter/views/datakontrol/Kontrolgruppe/KontrolGruppe.vue'),
          meta: {
            title: 'Indstillinger Datakontrol',
            requiresAuth: true,
            breadCrumb() {
              return [
                {
                  text: 'Kontroloversigt',
                  to: { name: 'kontroller' },
                },
                {
                  text: 'Kontrolgrupper',
                  to: { name: 'kontrolgrupper' },
                },
                {
                  text: 'Kontrolgruppeopsætning',
                },
              ];
            },
          },
        },
      ],
    },
    {
      path: '/settings/socialtilbud',
      name: 'indstillinger-socialtilbud',
      meta: { requiresAuth: true, title: 'Indstillinger Socialtilbud' },
      component: () => import('./forvalter/views/socialtilbud/SocialtilbudSettingsView.vue'),
    },
  ],
  menu:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    [
      {
        title: i18n.global.t('menu.Settings'),
        entryPointUrl: '/settings',
        iconName: 'mdi-cog',
        priority: 130,
        name: 'settings',
        userRightNames: ['menupunktindstillinger', 'MenupunktDatakontrolIndstillinger'],
      },
    ],
};
