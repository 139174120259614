<template>
  <div v-if="moreMenuItems">
    <q-btn
      dense
      @click.stop="showMenu = !showMenu"
      round
      flat
      icon="r_more_vert" />
    <q-menu
      dense
      v-model="showMenu">
      <q-list
        dense
        style="min-width: 100px">
        <q-item
          v-for="menu in moreMenuItems"
          :data-test-id="`more-action-item-${menu.action}`"
          :key="menu.action"
          @click="handleMenuAction(menu.action, menu.id)"
          clickable
          v-close-popup>
          <q-item-section>{{ menu.title }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
  export interface MoreMenuItem {
    id?: string;
    title: string;
    action: string;
  }

  interface Props {
    moreMenuItems?: MoreMenuItem[];
  }

  const showMenu = ref<boolean>(false);

  const emit = defineEmits<{
    (e: 'menu-action', payload: { action: string; id?: string }): void;
  }>();

  const handleMenuAction = (action: string, id?: string) => {
    emit('menu-action', { action, id });
  };

  defineProps<Props>();
</script>
