<template>
  <IstModal
    data-test-id="dialog-help"
    title="Hjælp">
    <div v-html="htmlstring" />
    <template #footer>
      <ISTButton
        data-test-id="btn-help-dialog"
        variant="neutral"
        rounded
        elevated
        size="lg"
        @click="close">
        Luk
      </ISTButton>
    </template>
  </IstModal>
</template>

<script setup lang="ts">
  import IstModal from '@/components/IstModal.vue';
  import vnode from './vnode';

  export interface HelpProps {
    helpId?: string;
  }

  const { helpId } = defineProps<HelpProps>();
  const htmlstring = ref('');

  const emits = defineEmits<(e: 'close', result: boolean) => void>();

  onMounted(async () => {
    const module = await import(`./../help/help${helpId}.htm`);
    htmlstring.value = vnode.created(module.render());
  });

  const close = () => {
    emits('close', false);
  };
</script>
