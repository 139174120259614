<template>
  <q-avatar
    :key="initials"
    size="32px"
    font-size="14px"
    text-color="white">
    {{ computedInitials }}
  </q-avatar>
</template>

<script setup lang="ts">
  export interface IstAvatarProps {
    fullName: string;
    initials?: string;
    useGeoPattern?: boolean;
  }
  const props = withDefaults(defineProps<IstAvatarProps>(), { initials: '' });

  const computedInitials = computed((): string => {
    return props.initials ? props.initials : getInitials(props.fullName);
  });

  // should extract to css class

  const getInitials = (name: string) => {
    const outString = name.replaceAll(/[`1234567890~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/g, '');
    const namesplit = outString.split(' ');

    // Extract the first two characters of a string
    if (namesplit.length == 1) {
      return namesplit[0].trim().substring(0, 2).toUpperCase();
    } else {
      return namesplit
        .map((localName) => localName[0])
        .join('')
        .toUpperCase();
    }
  };
</script>
