<template>
  <div class="forside-loading-container">
    <p><q-spinner size="3em" />Henter brugeroplysninger</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .forside-loading-container {
    display: flex;
    height: calc(100vh - 70px);
    flex-direction: column;
    gap: 12px;
  }
</style>
