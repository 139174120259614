import BeskedModule from './beskedindbakke/settings';
import DatakontrolModule from './datakontrol/settings';
import MainModule from './main/settings';
import RapportModule from './rapporter/settings';
import SettingsModule from './settings/settings';
import SocialTilbudModule from './socialtilbud/settings';
import SupportModule from './support/settings';

export default {
  SocialTilbudModule,
  MainModule,
  SettingsModule,
  RapportModule,
  BeskedModule,
  DatakontrolModule,
  SupportModule,
};
