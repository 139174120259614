<template>
  <IstFormInput
    :data-test-id="dataTestId"
    class="search-field"
    dense
    clearable
    outlined>
    <template #append>
      <q-btn
        :data-test-id="'btn-' + dataTestId"
        @click="emit('btn-click')"
        unelevated
        class="search-btn"
        :label="btnLabel"
        :disable="btnDisable"
        :loading="loading"
        color="primary"
        type="submit" />
    </template>
  </IstFormInput>
</template>

<script setup lang="ts">
  import IstFormInput from './IstFormInput.vue';

  interface Props {
    dataTestId?: string;
    btnLabel: string;
    btnDisable?: boolean;
    loading?: boolean;
  }
  defineProps<Props>();
  const emit = defineEmits<{
    (e: 'btn-click'): void;
  }>();
</script>

<style scoped>
  .search-field {
    overflow: hidden;
  }
  .search-btn {
    border-radius: 0 4px 4px 0;
    margin-right: -12px;
    height: 100%;
  }
</style>
