<template>
  <q-btn
    data-test-id="unread-messages-btn"
    v-if="unreadMessages > 0"
    class="ist-header-toolbar--item"
    round
    flat
    unelevated
    icon="mdi-bell"
    @click="goToBeskeder">
    <q-badge
      data-test-id="number-unread-messages"
      style="top: 12px"
      color="red"
      floating
      v-if="unreadMessages > 0">
      {{ unreadMessages }}
    </q-badge>
  </q-btn>
</template>

<script setup lang="ts">
  import { useRouter } from 'vue-router';
  import { GetNumberOfNewMessagesQuery } from '@/generated/graphqlSubcriptionTypes';
  import { getNumberOfNewMessages } from '@/graphql/documents/userQueries/beskeder.graphql';

  const router = useRouter();
  const unreadMessages = ref<number>(0);

  const { onResult } = useQuery<GetNumberOfNewMessagesQuery>(getNumberOfNewMessages, null, {
    pollInterval: 120000,
    context: { clientName: 'client' },
  });

  onResult((result) => {
    if (result.data?.beskeder) {
      unreadMessages.value = result.data.beskeder?.totalCount ?? 0;
    }
  });

  const goToBeskeder = async () => {
    await router.push({ name: 'beskeder' });
  };
</script>

<style scoped></style>
