import { defineStore } from 'pinia';
import { NavigationItem } from '@/components/types';
import { ViewAccessRight } from '@/generated/graphqlUser';
import Modules from '@/modules';
import { User, useSecurityStore } from '@/modules/security/store-index';
import { NavigationState } from './types';

const initialState: NavigationState = {
  currentRoute: '',
  currentTitle: '',
  menuitems: [],
};

export const useNavigationStore = defineStore('navigation', {
  state: (): NavigationState => initialState,
  getters: {
    getMenu:
      (state) =>
      (user: User): NavigationItem[] => {
        const { can } = useSecurityStore();

        return state.menuitems.flat().filter((menuItem) => {
          if (menuItem.hideFrom === user.userType) {
            return false;
          }
          return menuItem.userRightNames?.some((menuItemName) =>
            can(ViewAccessRight.Read, menuItemName),
          );
        });
      },
  },
  actions: {
    setCurrentTitle(title: string) {
      this.currentTitle = title;
    },
    replaceMenu(menus: NavigationItem[]) {
      this.menuitems = menus;
    },
    loadMenu() {
      const keys = Object.keys(Modules);
      keys.forEach((key) => {
        this.menuitems.push(Modules[key].menu);
      });
    },
  },
});
