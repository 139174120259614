<template>
  <QSelect
    data-test-id="q-select"
    v-model="selectedOptions"
    :stack-label="!dense"
    :use-input="hasInput"
    fill-input
    :multiple="multiple"
    :use-chips="multiple && useChips"
    ref="qSelect"
    :options="curOptions"
    :disable="disabled"
    :readonly="readonly || disabled"
    :label="label"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    style="padding-bottom: 0"
    :input-style="inputStyle"
    :dense="dense">
    <template #option="scope">
      <div :data-test-id="`select-option-${scope.opt.value}`">
        <q-item-label
          header
          v-show="showGroupText(scope.opt.grouptext, scope.index)">
          {{ scope.opt.grouptext }}
        </q-item-label>
        <q-item v-bind="scope.itemProps">
          <q-item-section
            avatar
            v-if="scope.opt.icon">
            <IstIcon :name="scope.opt.icon" />
          </q-item-section>
          <q-item-section :data-test-id="`select-item-${scope.opt.value}`">
            <q-item-label><div v-text="scope.opt.label" /></q-item-label>
            <q-item-label caption>
              {{ scope.opt.description }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>
  </QSelect>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue';
  import { QSelect } from 'quasar';
  import { SelectionOption } from './types';

  const qSelect = ref<QSelect>();

  // Define types for model value
  type ModelValue = string | number | object | Array<string | number | object> | undefined | null;

  interface IstFormSelectProps {
    modelValue?: string | number;
    options: Array<SelectionOption>;
    label?: string;
    disabled?: boolean;
    loading?: boolean;
    dense?: boolean;
    multiple?: boolean;
    inputStyle?: string;
    readonly?: boolean;
    hasInput?: boolean;
    useChips?: boolean;
  }

  const {
    modelValue,
    options,
    label,
    disabled = false,
    dense = false,
    multiple = false,
    inputStyle = '',
    readonly = false,
    hasInput = false,
    useChips = false,
  } = defineProps<IstFormSelectProps>();

  const emit = defineEmits<{
    (e: 'input', value: ModelValue): void;
    (e: 'change', value: ModelValue): void;
    (e: 'update:modelValue', value: ModelValue): void;
  }>();

  const ldense = ref(false);
  const curOptions = ref<Array<SelectionOption>>([]);
  const localvalue = ref<ModelValue>();

  const updateValue = () => {
    emit('input', localvalue.value);
    emit('change', localvalue.value);
    emit('update:modelValue', localvalue.value);
  };

  const onOptionsChanged = (val: SelectionOption[]) => {
    curOptions.value = val;
    let oldtxt = '';
    for (const element of curOptions.value) {
      const s = element.grouptext ? element.grouptext : '';
      if (s !== '' && oldtxt != s) {
        oldtxt = s;
      } else {
        element.grouptext = undefined;
      }
    }
  };

  onMounted(() => {
    if (modelValue) {
      localvalue.value = modelValue;
    }
    if (dense) {
      ldense.value = dense;
    }
    onOptionsChanged(options);
  });

  watch(
    () => modelValue,
    () => {
      localvalue.value = modelValue;
    },
  );

  const showGroupText = (groupText: string, index: number) => {
    if (groupText === undefined) {
      return false;
    }
    if (index === 0) {
      return groupText === '' ? false : true;
    }
    const previusOption = curOptions.value[index - 1];
    return previusOption.grouptext !== groupText && groupText !== '' ? true : false;
  };

  const selectedOptions = computed({
    get() {
      if (multiple) {
        if (localvalue.value) {
          return options.filter((value: SelectionOption) => {
            return (
              Array.isArray(localvalue.value) &&
              localvalue.value.some((localVal: string | number | object) =>
                typeof value.value === 'object' && typeof localVal === 'object'
                  ? objectComparer(
                      localVal as Record<string, unknown>,
                      value.value as Record<string, unknown>,
                    )
                  : localVal === value.value,
              )
            );
          });
        }
        return [];
      } else if (modelValue) {
        return options.find((value: SelectionOption) => {
          return localvalue.value === value.value;
        });
      } else {
        return '';
      }
    },
    set(selection: SelectionOption | SelectionOption[] | null) {
      if (multiple) {
        if (Array.isArray(selection)) {
          localvalue.value = selection.map((value: SelectionOption) => value.value);
        } else {
          localvalue.value = undefined;
        }
        (qSelect.value as QSelect).updateInputValue('', false);
      } else {
        localvalue.value = selection;
      }
      updateValue();
    },
  });

  const objectComparer = (
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>,
  ): boolean => {
    if (
      'equals' in obj1 &&
      'equals' in obj2 &&
      typeof obj1.equals === 'function' &&
      typeof obj2.equals === 'function'
    ) {
      return obj1.equals(obj2) && obj2.equals(obj1);
    }

    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    for (const key of obj1Keys) {
      const obj1Val = obj1[key];
      const obj2Val = obj2[key];

      if (obj1Val === undefined) {
        if (obj2Val !== undefined) {
          return false;
        }
      }

      if (obj1Val !== obj2Val) {
        return false;
      }
    }
    return true;
  };
</script>
<style scoped>
  .showerror {
    padding-bottom: 20px !important;
  }
</style>
