import type { App } from 'vue';
// COMMON
import IstButton from './common/IstButton.vue';
import IstCheckbox from './common/IstCheckBox.vue';
import IstChip from './common/IstChip.vue';
import IstField from './common/IstField.vue';
import IstList from './common/IstList.vue';
import IstLoadingPage from './common/IstLoadingPage.vue';
import IstMoreActionsButton from './common/IstMoreActionsButton.vue';
import IstPersonListItem from './common/IstPersonListItem.vue';
import IstValidationIssueList from './common/IstValidationIssueList.vue';
// FORM
import IstFormInput from './forms/IstFormInput.vue';
import IstFormInputWithButtonGroup from './forms/IstFormInputWithButtonGroup.vue';
import IstFormSelect from './forms/IstFormSelect.vue';
// LAYOUT & UI
import IstAliasIcon from './IstAliasIcon.vue';
import IstAvatar from './IstAvatar.vue';
import IstCardContent from './IstCardContent.vue';
import IstDomainActionChip from './IstDomainActionChip.vue';
import IstField2 from './IstField2.vue';
import IstHelpbutton from './IstHelpbutton.vue';
import IstLayoutCard from './IstLayoutCard.vue';
import IstMessageNotifications from './IstMessageNotifications.vue';
import IstModal from './IstModal.vue';
import IstNotifications from './IstNotificationCenter.vue';

// Type definitions for all components
export type ISTComponents = {
  // COMMON
  ISTButton: typeof IstButton;
  ISTCheckbox: typeof IstCheckbox;
  ISTChip: typeof IstChip;
  ISTField: typeof IstField;
  ISTList: typeof IstList;
  ISTLoadingPage: typeof IstLoadingPage;
  ISTMoreActionsButton: typeof IstMoreActionsButton;
  ISTPersonListItem: typeof IstPersonListItem;
  ISTValidationIssueList: typeof IstValidationIssueList;

  // FORM
  ISTFormInput: typeof IstFormInput;
  ISTFormInputWithButtonGroup: typeof IstFormInputWithButtonGroup;
  ISTFormSelect: typeof IstFormSelect;

  // LAYOUT & UI
  ISTAliasIcon: typeof IstAliasIcon;
  ISTAvatar: typeof IstAvatar;
  ISTCardContent: typeof IstCardContent;
  ISTDomainActionChip: typeof IstDomainActionChip;
  ISTField2: typeof IstField2;
  ISTHelpbutton: typeof IstHelpbutton;
  ISTLayoutCard: typeof IstLayoutCard;
  ISTMessageNotifications: typeof IstMessageNotifications;
  ISTModal: typeof IstModal;
  ISTNotifications: typeof IstNotifications;
};

// Augment Vue's GlobalComponents interface
declare module '@vue/runtime-core' {
  export interface GlobalComponents extends ISTComponents {}
}

// Plugin installation
export default {
  install(app: App) {
    // COMMON
    app.component('ISTButton', IstButton);
    app.component('ISTCheckbox', IstCheckbox);
    app.component('ISTChip', IstChip);
    app.component('ISTField', IstField);
    app.component('ISTList', IstList);
    app.component('ISTLoadingPage', IstLoadingPage);
    app.component('ISTMoreActionsButton', IstMoreActionsButton);
    app.component('ISTPersonListItem', IstPersonListItem);
    app.component('ISTValidationIssueList', IstValidationIssueList);

    // FORM
    app.component('ISTFormInput', IstFormInput);
    app.component('ISTFormInputWithButtonGroup', IstFormInputWithButtonGroup);
    app.component('ISTFormSelect', IstFormSelect);

    // LAYOUT & UI
    app.component('ISTAliasIcon', IstAliasIcon);
    app.component('ISTAvatar', IstAvatar);
    app.component('ISTCardContent', IstCardContent);
    app.component('ISTDomainActionChip', IstDomainActionChip);
    app.component('ISTField2', IstField2);
    app.component('ISTHelpbutton', IstHelpbutton);
    app.component('ISTLayoutCard', IstLayoutCard);
    app.component('ISTMessageNotifications', IstMessageNotifications);
    app.component('ISTModal', IstModal);
    app.component('ISTNotifications', IstNotifications);
  },
};

// Export individual components for direct imports
export {
  // COMMON
  IstButton,
  IstCheckbox,
  IstChip,
  IstField,
  IstList,
  IstLoadingPage,
  IstMoreActionsButton,
  IstPersonListItem,
  IstValidationIssueList,

  // FORM
  IstFormInput,
  IstFormInputWithButtonGroup,
  IstFormSelect,

  // LAYOUT & UI
  IstAliasIcon,
  IstAvatar,
  IstCardContent,
  IstDomainActionChip,
  IstField2,
  IstHelpbutton,
  IstLayoutCard,
  IstMessageNotifications,
  IstModal,
  IstNotifications,
};
