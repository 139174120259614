<template>
  <vue-final-modal
    :resize="true"
    classes="modal-container"
    content-class="modal-content"
    :z-index="5000"
    v-slot="{ close }"
    v-bind="$attrs">
    <q-card
      :data-test-id="componentName"
      class="dialog-content"
      :style="{
        'max-width': maxsize + 'px',
        'max-height': maxheight + 'px',
        'min-width': minsize + 'px',
      }">
      <q-card-section class="dialog-top">
        <div class="text-h6 dialog-top-left">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="dialog-top-right">
          <slot name="topright" />
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="scroll">
        <slot />
      </q-card-section>
      <q-card-actions
        :align="actionsAlign"
        class="dialog-bottom">
        <slot name="footer">
          <q-btn
            rounded
            color="primary"
            @click="close">
            {{ t('Close') }}
          </q-btn>
        </slot>
      </q-card-actions>
    </q-card>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { getCurrentInstance, onMounted, onUnmounted } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const componentName =
    getCurrentInstance()?.parent?.type.name || getCurrentInstance()?.parent?.type.__name || '';

  interface ModalProps {
    title: string;
    breakpoint?: number;
    maxsize?: number;
    minsize?: number;
    maxheight?: number;
    overlay?: boolean;
    urlpath?: string;
    actionsAlign?: 'left' | 'center' | 'right' | 'between' | 'around' | 'stretch';
    escapeCallback?: () => void;
    enterCallback?: () => void;
  }

  const {
    title,
    maxsize = 1024,
    minsize = 300,
    maxheight,
    actionsAlign = 'right',
  } = defineProps<ModalProps>();

  const emit = defineEmits<{
    (e: 'close', result: boolean): void;
  }>();

  onMounted(() => {
    // Der bruges window.addEventListener da for mange komponenter bruger og overskriver
    // v-on:key. Den kan også skrives om til at være mere dynamisk, men vi har ikke flere
    // usecases end disse.
    window.addEventListener('keydown', handleKeyEvents);
  });

  const handleKeyEvents = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      emit('close', false);
    }
  };

  onUnmounted(() => {
    window.removeEventListener('keydown', handleKeyEvents);
  });
</script>

<style scoped lang="scss">
  .scroll {
    background-color: #f5f5f5;
    z-index: 0;
  }

  :deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    border-radius: 0.25rem;
  }
  .message-box {
    min-width: 240px;
    z-index: 5010;
  }

  .dialog-mask {
    z-index: 5009;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.33);
  }

  .dialog-content {
    max-width: 90%;
    margin: 0 20px;
    padding: 0;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    background-color: #fff;
    -ms-flex-align: left;
    align-items: left;
    -ms-flex-pack: left;
    justify-content: left;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    color: #303133;
    overflow: auto;
    max-height: 100vh;
  }

  .dialog-content header {
    margin: 10px 0 26px;
    font-size: 18px;
    font-weight: 700;
  }

  .dialog-content .el-button,
  .dialog-content p {
    font-size: inherit;
  }

  .dialog-content p {
    white-space: pre-wrap;
  }

  .dialog-content .el-button {
    -ms-flex: 1;
    flex: 1;
    border: 0;
    color: #409eff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .dialog-content footer {
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
  }
  .dialog-bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    min-height: 55px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    overflow: hidden;
  }

  :deep(.dialog-bottom) button {
    margin-left: 12px;
  }

  .dialog-top {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    flex: 1;
    display: flex;
  }

  .dialog-top-left {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .dialog-top-right {
    display: flex;
    flex: 0;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
  }

  .dialogfooter {
    min-height: 55px;
  }
</style>
