<template>
  <div
    class="ist-list"
    :class="{ bordered: bordered, shimmer: shimmer }">
    <transition-group
      :name="showTransitions ? 'list' : ''"
      tag="ul">
      <li
        class="item"
        v-for="(item, index) in list"
        :key="index">
        <slot :item="item" />
        <div
          class="separator"
          v-if="list.length - 1 !== index && separator" />
      </li>
    </transition-group>
  </div>
</template>

<script setup lang="ts" generic="T">
  export interface ListProps<T> {
    list: Array<T>;
    bordered?: boolean;
    separator?: boolean;
    shimmer?: boolean;
    showTransitions?: boolean;
  }

  const {
    list,
    bordered = false,
    separator,
    shimmer = false,
    showTransitions,
  } = defineProps<ListProps<T>>();

  // The 49 should be extracted at some point to a template variable
  const height = computed(() => {
    return (list?.length || 1) * 44 + 'px';
  });
</script>

<style scoped lang="scss">
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .ist-list {
    padding: 0;
    height: v-bind(height);
    transition: height 0.5s ease-out;
    flex: 1;
  }
  .bordered {
    border: 1px solid $ist-color-grey-light;
    border-radius: 5px;
    overflow: hidden;
  }
  .separator {
    border-top: 1px solid $ist-color-grey-light;
  }

  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  /* 2. declare enter from and leave to state */
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
  }

  /* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
  .list-leave-active {
    position: absolute;
  }

  .shimmer {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 150px;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
</style>
