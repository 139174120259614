<template>
  <IstChip
    :ripple="false"
    v-if="showChip"
    :data-test-id="'chip-barn-issue-'"
    :key="domainAction.entityId"
    :style="{ backgroundColor: chipColor, color: textColor }">
    {{ text }}
  </IstChip>
</template>

<script setup lang="ts">
  import { format } from 'date-fns';
  import { IstChip } from '@/components/common';
  import { DomainAction, DomainActionSeverity, DomainActionType } from '@/generated/graphql';
  import ColorMixin, { eAlertColor, eDangerColor, eGreyColor } from '@/mixins/colorMixin';
  import { danishFormat } from '@/utils/dateUtils';

  export interface Issues extends DomainAction {
    index: number;
    text: string;
    color: string;
    actionType: DomainAction;
  }

  export type ViewPerspective = 'TILBUD_OVERSIGT' | 'BOERN_OVERSIGT';

  interface DomainActionProps {
    domainAction: DomainAction;
    typesToUse?: DomainActionType[];
    viewPerspective?: ViewPerspective;
  }

  const props = defineProps<DomainActionProps>();

  const showChip = computed(() => {
    if (!props.typesToUse) {
      return true;
    }
    return props.typesToUse.some((type) => {
      return type === props.domainAction.type;
    });
  });

  const chipColor = computed((): string => {
    switch (props.domainAction.severity) {
      case DomainActionSeverity.Low:
        return eGreyColor.light;
      case DomainActionSeverity.Medium:
        return eAlertColor.standard;
      case DomainActionSeverity.High:
        return eDangerColor.standard;
      default:
        return eGreyColor.light;
    }
  });

  const textColor = computed(() => {
    return ColorMixin.methods.pickTextColorBasedOnBgColor(chipColor.value);
  });

  const text = computed(() => {
    switch (props.domainAction.type) {
      case DomainActionType.TilbudsmedarbejderBarnGodkendelseUdloeberSnart: {
        const date = props.domainAction.values[0].value;
        const formatedDate = format(new Date(date), danishFormat);

        return `En medarbejdertilknytning udløber d. ${formatedDate}`;
      }
      case DomainActionType.TilbudTilknytningErFremtidig: {
        const date = props.domainAction.values[0].value;
        const formatedDate = format(new Date(date), danishFormat);
        return `Barn starter først d.  ${formatedDate}`;
      }
      case DomainActionType.TilbudTilknytningUdloeberSnart: {
        const date = props.domainAction.values[0].value;
        const formatedDate = format(new Date(date), danishFormat);
        return `Tilknytning udløber d. ${formatedDate}`;
      }
      case DomainActionType.MedarbejderHarUdloebsdato: {
        const date = props.domainAction.values[0].value;
        const formatedDate = format(new Date(date), danishFormat);
        return `Medarbejder har slutdato: ${formatedDate}`;
      }
      case DomainActionType.TilbudTilknytningGodkendelseManglerGodkendelse: {
        if (props.viewPerspective == 'TILBUD_OVERSIGT') {
          return 'Tilbuddet har ubekræftede tilknytninger';
        }
        if (props.viewPerspective == 'BOERN_OVERSIGT') {
          return 'Tilknytning skal bekræftes';
        }
        return 'Tilknytningen er ikke bekræftet';
      }
      case DomainActionType.BarnManglerMedarbejdere: {
        return 'Ingen medarbejdere tilknyttet';
      }
      case DomainActionType.MedarbejderManglerBoern: {
        return 'Medarbejder har ingen børn tilknyttet';
      }
      case DomainActionType.MedarbejderUdloeberSnart: {
        return 'Medarbejder udløber snart';
      }
      case DomainActionType.TilbudManglerBoern: {
        return 'Ingen børn på tilbuddet';
      }
      case DomainActionType.TilbudManglerMedarbejdere: {
        return 'Tilbuddet har ikke oprettet medarbejdere';
      }
      case DomainActionType.TilbudsmedarbejderBarnGodkendelseNaesteValideringIndenOverskredet: {
        return 'Validering af medarbejdertilknytning overskredet';
      }

      default:
        return 'Ukendt problem';
    }
  });
</script>
