<template>
  <div
    v-if="props.updating"
    class="CardContentProgress">
    <q-linear-progress indeterminate />
  </div>
  <div class="CardContent">
    <slot />
  </div>
</template>

<script setup lang="ts">
  interface CardContentProps {
    /**
     * makes an animated line at the bottom
     */
    updating?: boolean;
  }

  const props = withDefaults(defineProps<CardContentProps>(), { updating: false });
</script>

<style scoped lang="scss">
  .CardContentProgress {
    margin-left: -16px;
    margin-right: -16px;
  }
  @import '../assets/variables';
</style>
