<template>
  <q-field v-bind="$attrs">
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope">
      <slot />
      <slot
        :name="slot"
        v-bind="scope" />
    </template>
  </q-field>
</template>

<script setup lang="ts"></script>
