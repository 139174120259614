<template>
  <div />
</template>

<script setup lang="ts">
  import { Notify } from 'quasar';
  import { useMessageQueueStore } from '@/store/messagequeue';

  const messageQueueStore = useMessageQueueStore();
  const currentMessage = computed(() => {
    return messageQueueStore.currentMessage;
  });

  watch(currentMessage, async (newMessage) => {
    switch (newMessage?.type) {
      case 'InformationDataSubmitted': {
        Notify.create({
          message: newMessage.message || '',
          color: 'danger',
          icon: 'mdi-information',
          position: 'bottom-right',
          progress: true,
          actions: [
            {
              label: 'luk',
              color: 'info',
              handler: () => {
                /**... */
              },
            },
          ],
        });
        break;
      }
      case 'InformationDataDone': {
        Notify.create({
          message: newMessage.message || '',
          color: 'positive',
          icon: 'mdi-information',
          position: 'bottom-right',
          progress: true,
          actions: [
            {
              label: 'luk',
              color: 'info',
              handler: () => {
                /**... */
              },
            },
          ],
        });
        break;
      }
      case 'BreakingDataError': {
        Notify.create({
          message: newMessage.message || '',
          color: 'danger',
          icon: 'mdi-alert-circle',
          position: 'bottom',
          progress: true,
          actions: [
            {
              label: 'luk',
              color: 'white',
              handler: () => {
                /**... */
              },
            },
          ],
        });
        break;
      }
      case 'NonBreakingDataError': {
        Notify.create({
          message: newMessage.message || '',
          color: 'danger',
          icon: 'mdi-alert-circle',
          position: 'bottom',
          progress: true,
          actions: [
            {
              label: 'luk',
              color: 'white',
              handler: () => {
                /**... */
              },
            },
          ],
        });
        break;
      }
    }
  });
</script>

<style lang="scss">
  .bg-danger {
    background-color: $ist-color-red;
  }
</style>
