export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

/** A connection to a list of items. */
export type AffectedEntitiesConnection = {
  __typename?: 'AffectedEntitiesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AffectedEntitiesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<AffectedEntity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AffectedEntitiesEdge = {
  __typename?: 'AffectedEntitiesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AffectedEntity;
};

export type AffectedEntity = {
  __typename?: 'AffectedEntity';
  created: Scalars['DateTime']['output'];
  entityId: Scalars['UUID']['output'];
  entityType: EntityType;
  id: Scalars['UUID']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type AffectedEntityFilterInput = {
  and?: InputMaybe<Array<AffectedEntityFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  entityId?: InputMaybe<UuidOperationFilterInput>;
  entityType?: InputMaybe<EntityTypeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AffectedEntityFilterInput>>;
};

export type AffectedEntitySortInput = {
  created?: InputMaybe<SortEnumType>;
  entityId?: InputMaybe<SortEnumType>;
  entityType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BrugerAdgang = {
  __typename?: 'BrugerAdgang';
  brugerAdgangStatus: BrugerAdgangStatusType;
  brugerAktiviteter?: Maybe<BrugerAktiviteterConnection>;
  brugerInvitationer?: Maybe<BrugerInvitationerConnection>;
  created: Scalars['DateTime']['output'];
  cvrNummer: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  kommuneId?: Maybe<Scalars['UUID']['output']>;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  organisationId?: Maybe<Scalars['UUID']['output']>;
  organisationNavn: Scalars['String']['output'];
  tilbudId?: Maybe<Scalars['UUID']['output']>;
};


export type BrugerAdgangBrugerAktiviteterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BrugerAktivitetSortInput>>;
  where?: InputMaybe<BrugerAktivitetFilterInput>;
};


export type BrugerAdgangBrugerInvitationerArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<BrugerInvitationSortInput>>;
  where?: InputMaybe<BrugerInvitationFilterInput>;
};

export enum BrugerAdgangStatusType {
  CanAccess = 'CAN_ACCESS',
  CanNotAccess = 'CAN_NOT_ACCESS',
  CodeValidationExpired = 'CODE_VALIDATION_EXPIRED',
  NeedCodeValidation = 'NEED_CODE_VALIDATION',
  NeedLoginToAccess = 'NEED_LOGIN_TO_ACCESS'
}

export type BrugerAktivitet = {
  __typename?: 'BrugerAktivitet';
  affectedEntities?: Maybe<AffectedEntitiesConnection>;
  affectedOrganisationIds?: Maybe<Array<Scalars['UUID']['output']>>;
  aktivitet: Scalars['String']['output'];
  brugerId: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  elapsedMiliseconds: Scalars['Float']['output'];
  id: Scalars['UUID']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  variables?: Maybe<VariablesConnection>;
};


export type BrugerAktivitetAffectedEntitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<AffectedEntitySortInput>>;
  where?: InputMaybe<AffectedEntityFilterInput>;
};


export type BrugerAktivitetVariablesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<VariableSortInput>>;
  where?: InputMaybe<VariableFilterInput>;
};

export type BrugerAktivitetFilterInput = {
  affectedEntities?: InputMaybe<ListFilterInputTypeOfAffectedEntityFilterInput>;
  affectedOrganisationIds?: InputMaybe<ListUuidOperationFilterInput>;
  aktivitet?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<BrugerAktivitetFilterInput>>;
  brugerId?: InputMaybe<IntOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  elapsedMiliseconds?: InputMaybe<FloatOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BrugerAktivitetFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
  variables?: InputMaybe<ListFilterInputTypeOfVariableFilterInput>;
};

export type BrugerAktivitetSortInput = {
  aktivitet?: InputMaybe<SortEnumType>;
  brugerId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  elapsedMiliseconds?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BrugerAktiviteterConnection = {
  __typename?: 'BrugerAktiviteterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BrugerAktiviteterEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BrugerAktivitet>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BrugerAktiviteterEdge = {
  __typename?: 'BrugerAktiviteterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BrugerAktivitet;
};

export type BrugerInvitation = {
  __typename?: 'BrugerInvitation';
  brugerAdgangId: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  cvRnummer: Scalars['String']['output'];
  engangsKode: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  invitationExpiration: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  oprettetTidspunkt: Scalars['DateTime']['output'];
};

export type BrugerInvitationFilterInput = {
  and?: InputMaybe<Array<BrugerInvitationFilterInput>>;
  brugerAdgangId?: InputMaybe<IntOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  cvRnummer?: InputMaybe<StringOperationFilterInput>;
  engangsKode?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  invitationExpiration?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  oprettetTidspunkt?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<BrugerInvitationFilterInput>>;
};

export type BrugerInvitationSortInput = {
  brugerAdgangId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  cvRnummer?: InputMaybe<SortEnumType>;
  engangsKode?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  invitationExpiration?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  oprettetTidspunkt?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type BrugerInvitationerConnection = {
  __typename?: 'BrugerInvitationerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BrugerInvitationerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BrugerInvitation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BrugerInvitationerEdge = {
  __typename?: 'BrugerInvitationerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BrugerInvitation;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  accessRights: Array<ViewAccessRights>;
  activeEntityId?: Maybe<Scalars['UUID']['output']>;
  brugerAdgange: Array<BrugerAdgang>;
  customErrors: Array<CustomError>;
  cvr?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstForvalterLogin: Scalars['Boolean']['output'];
  hasActiveEntityId: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  kommunekode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userSessionHash: Scalars['Int']['output'];
  userType: UserType;
};

export type CustomError = {
  __typename?: 'CustomError';
  errorCode: Scalars['String']['output'];
  errorText: Scalars['String']['output'];
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum EntityType {
  AffectedEntities = 'AFFECTED_ENTITIES',
  Barn = 'BARN',
  Bruger = 'BRUGER',
  BrugerAdgang = 'BRUGER_ADGANG',
  BrugerAktivitet = 'BRUGER_AKTIVITET',
  BrugerInvitation = 'BRUGER_INVITATION',
  Gruppe = 'GRUPPE',
  GruppeInstitution = 'GRUPPE_INSTITUTION',
  Institution = 'INSTITUTION',
  Kommune = 'KOMMUNE',
  KommuneValideringskilde = 'KOMMUNE_VALIDERINGSKILDE',
  KommuneValideringskildeIndstilling = 'KOMMUNE_VALIDERINGSKILDE_INDSTILLING',
  Kontrol = 'KONTROL',
  KontrolloerInstitution = 'KONTROLLOER_INSTITUTION',
  KontrolInstitution = 'KONTROL_INSTITUTION',
  KontrolValideringsregel = 'KONTROL_VALIDERINGSREGEL',
  LokalKonfiguration = 'LOKAL_KONFIGURATION',
  Notifikation = 'NOTIFIKATION',
  OrganisationsEnhed = 'ORGANISATIONS_ENHED',
  Person = 'PERSON',
  Resultat = 'RESULTAT',
  Rollemapping = 'ROLLEMAPPING',
  Skabelon = 'SKABELON',
  SkabelonInstitution = 'SKABELON_INSTITUTION',
  SkabelonValideringsregel = 'SKABELON_VALIDERINGSREGEL',
  SystemKonfiguration = 'SYSTEM_KONFIGURATION',
  Tilbud = 'TILBUD',
  TilbudsmedarbejderBarn = 'TILBUDSMEDARBEJDER_BARN',
  TilbudsmedarbejderBarnGodkendelse = 'TILBUDSMEDARBEJDER_BARN_GODKENDELSE',
  TilbudsMedarbejder = 'TILBUDS_MEDARBEJDER',
  TilbudsTilknytning = 'TILBUDS_TILKNYTNING',
  TilbudsTilknytningGodkendelse = 'TILBUDS_TILKNYTNING_GODKENDELSE',
  TilbudInfo = 'TILBUD_INFO',
  Undefined = 'UNDEFINED',
  Valideringskilde = 'VALIDERINGSKILDE',
  Valideringsregel = 'VALIDERINGSREGEL',
  ValideringsregelIndstilling = 'VALIDERINGSREGEL_INDSTILLING',
  ValidResultat = 'VALID_RESULTAT',
  Variable = 'VARIABLE'
}

export type EntityTypeOperationFilterInput = {
  eq?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  neq?: InputMaybe<EntityType>;
  nin?: InputMaybe<Array<EntityType>>;
};

export type Environment = {
  __typename?: 'Environment';
  branch: Scalars['String']['output'];
  buildVersion: Scalars['String']['output'];
  commitHash?: Maybe<Scalars['String']['output']>;
  datadogUrl: Scalars['String']['output'];
  idpClientId: Scalars['String']['output'];
  idpRealm: Scalars['String']['output'];
  idpUrl: Scalars['String']['output'];
  moduleVersionId: Scalars['UUID']['output'];
  sentryUrl: Scalars['String']['output'];
  supportToolIdPClientId: Scalars['String']['output'];
  supportToolIdPRealm: Scalars['String']['output'];
};

export type EnvironmentQuery = {
  __typename?: 'EnvironmentQuery';
  currentUser: CurrentUser;
  environment: Environment;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFilterInputTypeOfAffectedEntityFilterInput = {
  all?: InputMaybe<AffectedEntityFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AffectedEntityFilterInput>;
  some?: InputMaybe<AffectedEntityFilterInput>;
};

export type ListFilterInputTypeOfVariableFilterInput = {
  all?: InputMaybe<VariableFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<VariableFilterInput>;
  some?: InputMaybe<VariableFilterInput>;
};

export type ListUuidOperationFilterInput = {
  all?: InputMaybe<UuidOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UuidOperationFilterInput>;
  some?: InputMaybe<UuidOperationFilterInput>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum UserType {
  Forvalter = 'FORVALTER',
  Ist = 'IST',
  System = 'SYSTEM',
  TilbudsmedarbejderAdmin = 'TILBUDSMEDARBEJDER_ADMIN',
  Ukendt = 'UKENDT'
}

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type Variable = {
  __typename?: 'Variable';
  brugerAktivitetId: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VariableFilterInput = {
  and?: InputMaybe<Array<VariableFilterInput>>;
  brugerAktivitetId?: InputMaybe<IntOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<VariableFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
  value?: InputMaybe<StringOperationFilterInput>;
};

export type VariableSortInput = {
  brugerAktivitetId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type VariablesConnection = {
  __typename?: 'VariablesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<VariablesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Variable>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VariablesEdge = {
  __typename?: 'VariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Variable;
};

export enum ViewAccessRight {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type ViewAccessRights = {
  __typename?: 'ViewAccessRights';
  entity: Scalars['String']['output'];
  rights: Array<ViewAccessRight>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'EnvironmentQuery', currentUser: { __typename?: 'CurrentUser', name?: string | null, userType: UserType, hasActiveEntityId: boolean, userSessionHash: number, cvr?: string | null, firstForvalterLogin: boolean, id: any, email?: string | null, accessRights: Array<{ __typename?: 'ViewAccessRights', entity: string, rights: Array<ViewAccessRight> }>, brugerAdgange: Array<{ __typename?: 'BrugerAdgang', kommuneId?: any | null, tilbudId?: any | null, organisationId?: any | null, organisationNavn: string, cvrNummer: string, brugerAdgangStatus: BrugerAdgangStatusType }>, customErrors: Array<{ __typename?: 'CustomError', errorCode: string, errorText: string }> } };

export type GetEnvironmentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEnvironmentQuery = { __typename?: 'EnvironmentQuery', environment: { __typename?: 'Environment', idpClientId: string, idpRealm: string, idpUrl: string, supportToolIdPClientId: string, supportToolIdPRealm: string, buildVersion: string, sentryUrl: string, datadogUrl: string, branch: string, commitHash?: string | null } };


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    